import React from "react";

interface Props {
  onClick: () => void;
}

export const BottonPrime = ({ onClick }: Props): React.ReactNode => {
  return (
    <div
      onClick={onClick}
      className="flex w-36 h-10 items-center justify-center gap-1 px-6 py-5 relative bg-prime-greenprime-green-80 rounded-[20px] border border-solid border-[#ffffff] shadow-shadow-prime backdrop-blur backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(8px)_brightness(100%)] cursor-pointer"
    >
      <div className="relative w-fit mt-[-11.00px] mb-[-9.00px] font-subhead-JP-b font-[number:var(--subhead-JP-b-font-weight)] text-fillture-white text-[length:var(--subhead-JP-b-font-size)] tracking-[var(--subhead-JP-b-letter-spacing)] leading-[var(--subhead-JP-b-line-height)] whitespace-nowrap [font-style:var(--subhead-JP-b-font-style)]">
        今すぐ始める
      </div>
    </div>
  );
};
