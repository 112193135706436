import React from "react";
import { createRoot } from "react-dom/client";
import MyPage from "./src/mypage";
import CourseRedirectHandler from "./src/CourseRedirectHandler";
import CourseDataFetcher from "./src/CourseDataFetcher";
import CourseFinishPage from "./src/CourseFinishPage";
import MypageRegistAddress from "./src/MypageRegistAddress";
import PaidFreeCourseHidden from "./src/PaidFreeCourseHidden";
import { Home } from "./src/Home";
import dynamicContentsInsert from "./src/dynamicContentsInsert";

document.addEventListener("DOMContentLoaded", () => {
  new CourseRedirectHandler().init();
  PaidFreeCourseHidden();

  const reactAnyElement = document.getElementById("react-any");
  if (reactAnyElement) {
    const root = createRoot(reactAnyElement);
    root.render(<CourseDataFetcher />);
  }

  const reactMypageElement = document.getElementById("react-mypage");
  if (reactMypageElement) {
    const root = createRoot(reactMypageElement);
    root.render(<MyPage />);
  }

  const reactMypageRegistAddress = document.getElementById(
    "react-mypage-regist-address"
  );
  if (reactMypageRegistAddress) {
    const root = createRoot(reactMypageRegistAddress);
    root.render(<MypageRegistAddress />);
  }

  const reactCourseFinishPage = document.getElementById(
    "react-course-finish-page"
  );
  if (reactCourseFinishPage) {
    const root = createRoot(reactCourseFinishPage);
    root.render(<CourseFinishPage />);
  }

  const reactHome = document.getElementById("react-home");
  if (reactHome) {
    const root = createRoot(reactHome);
    root.render(<Home />);
  }

  // .js-FS-Dynamic-Contents-Insert--hide の要素の中に Flow, FAQ, License があれば置換
  console.log(window.location.pathname.startsWith("/nesta-japan-license"));
  if (
    window.location.pathname.startsWith("/course/") ||
    window.location.pathname.startsWith("/about") ||
    window.location.pathname.startsWith("/nesta-japan-license")
  ) {
    dynamicContentsInsert();
  }

  // NESTA会員IDラベルのテキスト変更を0.3秒ごとに実行
  // ヘッダー .lw-topbar の要素があるときのみ実行
  const topbar = document.querySelector(".lw-topbar");
  if (topbar) {
    setInterval(() => {
      const nestaIdLabel = document.querySelector(
        "#signUpForm > div.form-input-group > div.-form-inputs > div.login-form-input-wrapper.mb-15.user-custom-field.form-input-wrapper.extra-form-input-wrapper.js-extraLogin_cf_nesta_id.learnworlds-main-text-normal > label"
      );
      if (nestaIdLabel) {
        nestaIdLabel.textContent =
          "NESTA会員ID （あとでマイページから登録可能です）";
      }

      const signUpLink = document.querySelector(
        "#animatedModal > div > div > div.login-form-inputs > div.login-form-cont > form > div.-form-create-forgot.flex.a-i-c.j-c-sb > div.inline-block.-create-account > a"
      );
      if (signUpLink) {
        signUpLink.textContent = "新規登録はこちら";
      }

      const signUpLinkSpan = document.evaluate(
        "/html/body/div[7]/div/div/div[2]/div[3]/form/div[4]/div[1]/a/span[1]",
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;

      if (signUpLinkSpan) {
        signUpLinkSpan.style.display = "none";
      }
    }, 300);
  }
});
