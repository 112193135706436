import React from "react";
import { BottonPrime } from "./ButtonPrimeMobile";
import frame2327 from "./assetsMobile/frame-2327.png";

export const HomeMobile = (): React.ReactNode => {
  return (
    <div
      className="flex flex-col h-[400px] items-start justify-center relative bg-cover bg-[50%_50%]"
      style={{ backgroundImage: `url(${frame2327})` }}
    >
      <div className="flex flex-col items-start gap-8 px-4 py-0 self-stretch w-full relative flex-[0_0_auto]">
        <div className="flex flex-col items-start gap-2 self-stretch w-full relative flex-[0_0_auto]">
          <div className="inline-flex flex-col items-start gap-[3px]">
            <p className="relative self-stretch [font-family:'Noto_Sans_JP-Regular',Helvetica] font-black text-fillture-white text-[22px] tracking-[-0.66px] leading-[26.4px]">
              <span className="tracking-[-0.15px]">
                最先端のフィットネス専門知識が学べる
                <br />
              </span>
            </p>
            <p className="relative self-stretch [font-family:'Noto_Sans_JP-Regular',Helvetica] font-black text-fillture-white text-[22px] tracking-[-0.66px] leading-[26.4px]">
              <span className="tracking-[-0.15px]">
                資格更新の単位を取得できる
                <br />
              </span>
            </p>
            <p className="relative self-stretch [font-family:'Noto_Sans_JP-Regular',Helvetica] font-black text-fillture-white text-[22px] tracking-[-0.66px] leading-[26.4px]">
              <span className="tracking-[-0.15px]">
                プロフェッショナルのための学習プラットフォーム
              </span>
            </p>
          </div>
          <div className="flex items-center gap-2 self-stretch w-full relative flex-[0_0_auto]">
            <div className="inline-flex items-center justify-center gap-2.5 px-1 py-0 relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-1.00px] font-footnote-EN-italic font-[number:var(--footnote-EN-italic-font-weight)] [font-style:var(--footnote-EN-italic-font-style)] text-fillture-white text-[length:var(--footnote-EN-italic-font-size)] tracking-[var(--footnote-EN-italic-letter-spacing)] leading-[var(--footnote-EN-italic-line-height)] whitespace-nowrap">
                supported by NESTA JAPAN
              </div>
            </div>
            <div className="relative flex-1 grow h-px bg-fillwhite-90" />
          </div>
        </div>
        {window.learnworldData.userId == "" && (
          <div className="inline-flex items-start gap-2 relative flex-[0_0_auto]">
            <BottonPrime
              onClick={() => {
                document.getElementById("menuItem_1594985863854_2").click();
              }}
            />
            <div
              className="flex w-36 h-10 items-center justify-center gap-1 px-6 py-5 relative bg-prime-blackprime-black-60 rounded-[46px] border border-solid border-[#ffffff] cursor-pointer"
              onClick={() => {
                document.getElementById("menuItem5").click();
              }}
            >
              <div className="relative w-fit mt-[-11.00px] mb-[-9.00px] font-subhead-JP-b font-[number:var(--subhead-JP-b-font-weight)] text-fillture-white text-[length:var(--subhead-JP-b-font-size)] tracking-[var(--subhead-JP-b-letter-spacing)] leading-[var(--subhead-JP-b-line-height)] whitespace-nowrap [font-style:var(--subhead-JP-b-font-style)]">
                ログイン
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
