import React from "react";
import { BottonPrime } from "./BottonPrime";
import frame2327 from "./assetsPC/frame-2327.png";

export const HomePC = (): React.ReactNode => {
  return (
    <div className="relative">
      <div className="absolute inset-0 text-center">
        <img
          className="w-full h-[600px] object-cover"
          alt="Frame"
          src={frame2327}
        />
      </div>
      <div className="relative z-10 flex flex-col max-w-[1170px] h-[600px] items-start justify-center md:px-3 md:py-0 px-20 py-10 mx-auto">
        <div className="inline-flex flex-col items-start gap-8 relative flex-[0_0_auto]">
          <div className="inline-flex flex-col items-start gap-2 relative flex-[0_0_auto]">
            <div className="inline-flex flex-col items-start gap-[3px]">
              <p className="relative self-stretch font-black text-fillture-white text-5xl tracking-[-1.44px] leading-[57.6px]">
                <span className="tracking-[-0.69px]">
                  最先端のフィットネス専門知識が学べる
                  <br />
                </span>
              </p>
              <p className="relative self-stretch font-black text-fillture-white text-5xl tracking-[-1.44px] leading-[57.6px]">
                <span className="tracking-[-0.69px]">
                  資格更新の単位を取得できる
                  <br />
                </span>
              </p>
              <p className="relative self-stretch font-black text-fillture-white text-5xl tracking-[-1.44px] leading-[57.6px]">
                <span className="tracking-[-0.69px]">
                  プロフェッショナルのための学習プラットフォーム
                </span>
              </p>
            </div>
            <div className="flex items-center gap-2 self-stretch w-full relative flex-[0_0_auto]">
              <div className="inline-flex items-center justify-center gap-2.5 px-2 py-0 relative flex-[0_0_auto]">
                <div className="relative w-fit mt-[-1.00px] font-body-EN-l-italic font-[number:var(--body-EN-l-italic-font-weight)] [font-style:var(--body-EN-l-italic-font-style)] text-fillture-white text-[length:var(--body-EN-l-italic-font-size)] tracking-[var(--body-EN-l-italic-letter-spacing)] leading-[var(--body-EN-l-italic-line-height)] whitespace-nowrap">
                  supported by NESTA JAPAN
                </div>
              </div>
              <div className="relative flex-1 grow h-0.5 bg-fillwhite-90" />
            </div>
          </div>
          {window.learnworldData.userId == "" && (
            <div className="flex w-[480px] h-14 items-center gap-2 relative">
              <BottonPrime
                className="!self-stretch !flex-1 !grow cursor-pointer"
                onClick={() => {
                  document.getElementById("menuItem_1594985863854_2").click();
                }}
              />
              <div
                className="bg-prime-blackprime-black-60 flex items-center justify-center gap-1 px-6 py-5 relative flex-1 self-stretch grow rounded-[46px] border border-solid border-[#ffffff] cursor-pointer"
                onClick={() => {
                  document.getElementById("menuItem5").click();
                }}
              >
                <div className="relative w-fit mt-[-4.00px] mb-[-2.00px] font-body-JP-headline font-[number:var(--body-JP-headline-font-weight)] text-fillture-white text-[length:var(--body-JP-headline-font-size)] tracking-[var(--body-JP-headline-letter-spacing)] leading-[var(--body-JP-headline-line-height)] whitespace-nowrap [font-style:var(--body-JP-headline-font-style)]">
                  ログイン
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
