import React from "react";

export const AboutConcept = ({
  isMobile,
}: {
  isMobile: boolean;
}): React.ReactNode => {
  return (
    <div className="md:mx-auto md:max-w-[1170px] flex flex-col md:flex-row items-center md:items-start justify-center px-4 py-10 md:py-20 relative bg-fillture-white">
      <div className="items-center justify-center gap-2.5 md:flex-1 md:self-stretch md:grow flex relative">
        <div className="relative w-[358px] md:w-full [font-family:'Poppins-MediumItalic',Helvetica] font-medium italic text-texttext-black text-4xl md:text-[140px] text-center md:text-left tracking-[-1.08px] md:tracking-[-4.20px] leading-9 md:leading-[140px] whitespace-nowrap md:whitespace-normal">
          Be a {!isMobile && <br />}
          Pioneer {!isMobile && <br />}
          Trainer
        </div>
      </div>
      <div className="flex-col items-start gap-10 pt-0 pb-4 px-0 md:flex-1 md:grow flex relative mt-8 md:mt-0">
        <div className="absolute w-[122px] md:w-[226px] h-[11px] md:h-[22px] top-10 md:top-[53px] -left-px md:left-0 bg-prime-greenprime-green-80hover" />
        <div className="flex-col items-start gap-4 self-stretch w-full flex-[0_0_auto] flex relative">
          <div className="flex-col items-start gap-2 self-stretch w-full flex-[0_0_auto] flex relative">
            <div className="relative self-stretch mt-[-1.00px] font-footnote-EN-italic md:font-body-EN-l-italic font-[number:var(--footnote-EN-italic-font-weight)] md:font-[number:var(--body-EN-l-italic-font-weight)] [font-style:var(--footnote-EN-italic-font-style)] md:[font-style:var(--body-EN-l-italic-font-style)] text-prime-greenprime-green text-[length:var(--footnote-EN-italic-font-size)] md:text-[length:var(--body-EN-l-italic-font-size)] tracking-[var(--footnote-EN-italic-letter-spacing)] md:tracking-[var(--body-EN-l-italic-letter-spacing)] leading-[var(--footnote-EN-italic-line-height)] md:leading-[var(--body-EN-l-italic-line-height)]">
              About
            </div>
            <div className="relative self-stretch font-title-02-JP-b md:font-largetitle-JP-m font-[number:var(--title-02-JP-b-font-weight)] md:font-[number:var(--largetitle-JP-m-font-weight)] text-fillprime-black text-[length:var(--title-02-JP-b-font-size)] md:text-[length:var(--largetitle-JP-m-font-size)] tracking-[var(--title-02-JP-b-letter-spacing)] md:tracking-[var(--largetitle-JP-m-letter-spacing)] leading-[var(--title-02-JP-b-line-height)] md:leading-[var(--largetitle-JP-m-line-height)] [font-style:var(--title-02-JP-b-font-style)] md:[font-style:var(--largetitle-JP-m-font-style)]">
              フィットネス業界で活躍する全ての人へ
            </div>
          </div>
          <div className="relative self-stretch font-subhead-JP-r md:font-body-JP-body font-[number:var(--subhead-JP-r-font-weight)] md:font-[number:var(--body-JP-body-font-weight)] text-texttext-gray-b text-[length:var(--subhead-JP-r-font-size)] md:text-[length:var(--body-JP-body-font-size)] tracking-[var(--subhead-JP-r-letter-spacing)] md:tracking-[var(--body-JP-body-letter-spacing)] leading-[var(--subhead-JP-r-line-height)] md:leading-[var(--body-JP-body-line-height)] [font-style:var(--subhead-JP-r-font-style)] md:[font-style:var(--body-JP-body-font-style)]">
            いままで、フィットネス業界で働く人材向けの講座は、主にオフラインで行われてきました。また、経営者、トレーナー、フロントスタッフなど、さまざまなフィールドで活動する人々は日々の業務に追われ、自身のスキルアップや資格の取得・更新に時間を割くのが難しいという課題がありました。
            <br />
            FitSchool™は、NESTA
            JAPANの監修コンテンツにより、最先端の学習をオンラインで誰でもいつでも可能にする、唯一のプラットフォームです。
          </div>
        </div>
        <div className="flex-col items-start gap-4 self-stretch w-full flex-[0_0_auto] flex relative">
          <div className="flex-col items-start gap-2 self-stretch w-full flex-[0_0_auto] flex relative">
            <div className="relative self-stretch mt-[-1.00px] font-footnote-EN-italic md:font-body-EN-l-italic font-[number:var(--footnote-EN-italic-font-weight)] md:font-[number:var(--body-EN-l-italic-font-weight)] [font-style:var(--footnote-EN-italic-font-style)] md:[font-style:var(--body-EN-l-italic-font-style)] text-prime-greenprime-green text-[length:var(--footnote-EN-italic-font-size)] md:text-[length:var(--body-EN-l-italic-font-size)] tracking-[var(--footnote-EN-italic-letter-spacing)] md:tracking-[var(--body-EN-l-italic-letter-spacing)] leading-[var(--footnote-EN-italic-line-height)] md:leading-[var(--body-EN-l-italic-line-height)]">
              Concept
            </div>
            <div className="relative self-stretch font-title-02-JP-b md:font-largetitle-JP-m font-[number:var(--title-02-JP-b-font-weight)] md:font-[number:var(--largetitle-JP-m-font-weight)] text-fillprime-black text-[length:var(--title-02-JP-b-font-size)] md:text-[length:var(--largetitle-JP-m-font-size)] tracking-[var(--title-02-JP-b-letter-spacing)] md:tracking-[var(--largetitle-JP-m-letter-spacing)] leading-[var(--title-02-JP-b-line-height)] md:leading-[var(--largetitle-JP-m-line-height)] [font-style:var(--title-02-JP-b-font-style)] md:[font-style:var(--largetitle-JP-m-font-style)]">
              NESTA JAPANの資格の単位が取得できる
            </div>
          </div>
          <ul className="list-disc pl-5 mb-4">
            <li className="mb-2 font-subhead-JP-r text-title-02-JP-r text-texttext-black">
              NESTA PFT資格の更新に必要な継続教育単位がオンラインで取得可能！
            </li>
            <li className="font-subhead-JP-r text-title-02-JP-r text-texttext-black">
              NESTA スペシャリスト資格がオンラインで取得可能！（2025年初頭予定）
            </li>
          </ul>
          <div className="relative self-stretch font-body-JP-body font-[number:var(--body-JP-body-font-weight)] text-texttext-gray-b text-[length:var(--body-JP-body-font-size)] tracking-[var(--body-JP-body-letter-spacing)] leading-[var(--body-JP-body-line-height)] [font-style:var(--body-JP-body-font-style)]">
            FitSchool™で、NESTA
            JAPANの資格の単位を取得。必要な知識やスキルを効率的に身につけることができます。あなたに合った学びを選んで、さらなる成長を目指しましょう。
            <a
              href="/nesta-japan-license"
              className="flex justify-center gap-1 px-6 py-5 relative bg-prime-greenprime-green-80 rounded-[46px] border border-solid border-[#ffffff] shadow-shadow-prime backdrop-blur backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(8px)_brightness(100%)] !self-stretch !flex-1 !grow cursor-pointer text-white mt-5 w-full items-end"
            >
              <span id="el_1728167878202_352">
                資格の取得更新、単位の取得について
              </span>
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.41009 3.57709C7.08466 3.90252 7.08466 4.43016 7.41009 4.7556L12.6539 9.99944L7.41009 15.2433C7.08466 15.5687 7.08466 16.0964 7.41009 16.4218C7.73553 16.7472 8.26317 16.7472 8.5886 16.4218L14.4217 10.5887C14.578 10.4324 14.6658 10.2205 14.6658 9.99944C14.6658 9.77843 14.578 9.56647 14.4217 9.41019L8.5886 3.57709C8.26317 3.25165 7.73553 3.25165 7.41009 3.57709Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
