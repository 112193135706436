import React from "react";

interface Props {
  className: any;
  onClick: () => void;
}

export const BottonPrime = ({ className, onClick }: Props): React.ReactNode => {
  return (
    <div
      className={`flex items-center justify-center gap-1 px-6 py-5 relative bg-prime-greenprime-green-80 rounded-[46px] border border-solid border-[#ffffff] shadow-shadow-prime backdrop-blur backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(8px)_brightness(100%)] ${className}`}
      onClick={onClick}
    >
      <div className="relative w-fit mt-[-4.00px] mb-[-2.00px] font-body-JP-headline font-[number:var(--body-JP-headline-font-weight)] text-fillture-white text-[length:var(--body-JP-headline-font-size)] tracking-[var(--body-JP-headline-letter-spacing)] leading-[var(--body-JP-headline-line-height)] whitespace-nowrap [font-style:var(--body-JP-headline-font-style)]">
        今すぐ始める
      </div>
    </div>
  );
};
