import React from "react";
import ShadowDomWrapper from "./components/ShadowDomWrapper";

const NestaIdFormModal: React.FC = () => {
  const handleClose = () => {
    const event = new CustomEvent("shadowDomEvent", {
      detail: { type: "closeModal" },
      bubbles: true,
      composed: true,
    });
    document.dispatchEvent(event);
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-4/5 lg:w-3/5 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            受講料について
          </h3>
          <div className="mt-2 px-7 py-3 text-left">
            <p className="font-bold">●受講料について</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                各講座の受講料には「通常料金」「NESTA
                PFT資格ホルダー」「NESTA有料会員」の3つのパターンがあります。
              </li>
              <li>
                受講料は、通常料金＞NESTA
                PFT資格ホルダー＞NESTA有料会員　の順に変動し、NESTA有料会員の皆様が最もお得に受講いただけます。
              </li>
              <li>
                マイページにNESTA会員IDを入力し、NESTA
                JAPANにて認証されることで、NESTA
                PFT資格ホルダーの皆様ならびにNESTA有料会員の皆様は割引金額で受講申込が可能となります。
              </li>
            </ul>
            <p className="font-bold mt-4">※初めて受講申込をされる場合※</p>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                NESTA会員IDがNESTA
                JAPANに認証されるまでに、2〜3営業日かかります。そのため、初回の受講料は、会員ステータスに関わらず「通常料金」にてお支払いください。
              </li>
              <li>
                お支払い後、NESTA
                JAPANにてIDが認証されると、差額分がクレジットカードへ返金されます。返金までには通常1〜2週間要します(クレジットカード会社の規定により異なります)。
              </li>
              <li>
                入力された会員IDで認証ができない場合、割引やご返金がなされませんので、必ず正しい情報を入力してください。
              </li>
              <li>
                NESTA会員IDの確認方法は、NESTA公式サイトよりマイページにログイン後、プロフィール→個人情報をご選択ください。ページ中央付近にある「会員ID」欄6桁の数字です。
              </li>
            </ul>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-black text-white text-base font-medium rounded-full w-full max-w-sm shadow-sm hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={handleClose}
            >
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadowDomWrapper(NestaIdFormModal);
