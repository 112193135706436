import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";

export default function ShadowDomWrapper(
  WrappedComponent: React.ComponentType
) {
  return () => {
    const hostRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      if (hostRef.current) {
        const shadowRoot = hostRef.current.attachShadow({ mode: "open" });
        const shadowContainer = document.createElement("div");
        shadowRoot.appendChild(shadowContainer);

        const linkElem = document.createElement("link");
        linkElem.setAttribute("rel", "stylesheet");
        linkElem.setAttribute("type", "text/css");
        linkElem.setAttribute(
          "href",
          "https://app-assets.fitschool.jp/assets/application.css"
        );
        shadowRoot.prepend(linkElem);

        // Google Fontsのスタイルシートの追加
        const googleFontsLink = document.createElement("link");
        googleFontsLink.setAttribute("rel", "stylesheet");
        googleFontsLink.setAttribute(
          "href",
          "https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        );
        shadowRoot.prepend(googleFontsLink);

        const DelayedRender: React.FC = () => {
          const [shouldRender, setShouldRender] = useState(false);

          useEffect(() => {
            const timer = setTimeout(() => {
              setShouldRender(true);
            }, 300);

            return () => clearTimeout(timer);
          }, []);

          return shouldRender ? <WrappedComponent /> : null;
        };

        ReactDOM.render(<DelayedRender />, shadowContainer);
      }
    }, []);

    return <div ref={hostRef}></div>;
  };
}
