import { useState, useEffect } from "react";
import config from "./config";

const CourseDataFetcher = () => {
  const currentPath = window.location.pathname;

  useEffect(() => {
    const fetchCourseLabel = async () => {
      if (currentPath.startsWith("/course/")) {
        // コースデータを取得してページに反映
        const courseId = currentPath.split("/course/")[1];
        try {
          const response = await fetch(
            `${config.apiUrl}/api/v1/lw_courses/${courseId}.json`
          );
          const data = await response.json();

          updateEnrollButton(data.label);
          updateNestaPFULabel(data.label);
          updateAuthorName(data.author);
          updateCourseDuration();
        } catch (error) {
          console.error("コースラベルの取得中にエラーが発生しました:", error);
        }
      }
    };

    fetchCourseLabel();
  }, [currentPath]);

  return null;
};

const updateEnrollButton = (label: string) => {
  const enrollButton = document.querySelector(".FS-BottomFixedEnroll-Button");
  if (enrollButton) {
    const buttonTextElement = enrollButton.querySelector(
      ".FS-BottomFixedEnroll-Button-Text > div:nth-child(2)"
    );
    if (buttonTextElement) {
      buttonTextElement.textContent = label;
    }
  }
};

const updateNestaPFULabel = (label: string) => {
  const elements = document.querySelectorAll(
    ".learnworlds-main-text.learnworlds-element.lw-text-color-fadeout20.learnworlds-main-text-large"
  );
  const targetElement = Array.from(elements).find((element) =>
    element.textContent.includes("NESTA CEU ()")
  );

  if (targetElement) {
    targetElement.textContent = label;
  }
};

const updateCourseDuration = () => {
  const startTime = Date.now();
  const timeoutDuration = 10000; // 10秒

  const calculateAndUpdateDuration = () => {
    if (Date.now() - startTime > timeoutDuration) {
      console.warn("コース時間の更新がタイムアウトしました");
      return;
    }

    const timeElements = document.querySelectorAll(
      ".hide-sp.ml-10.no-margin-bottom.learnworlds-main-text.learnworlds-element.learnworlds-main-text-normal.lw-text-color-fadeout50"
    );
    let totalSeconds = 0;
    const timeFormat = /^\d{2}:\d{2}$/;

    timeElements.forEach((element) => {
      const timeText = element.textContent.trim();
      if (timeFormat.test(timeText)) {
        const [minutes, seconds] = timeText.split(":").map(Number);
        totalSeconds += minutes * 60 + seconds;
      }
    });

    if (totalSeconds > 0) {
      const totalMinutes = Math.floor(totalSeconds / 60);
      const durationElements = document.querySelectorAll(
        ".learnworlds-main-text.learnworlds-element.lw-text-color-fadeout20.learnworlds-main-text-large"
      );
      const targetDurationElement = Array.from(durationElements).find(
        (element) => element.textContent.includes("60分")
      );

      if (targetDurationElement) {
        targetDurationElement.textContent =
          targetDurationElement.textContent.replace(
            "60分",
            `${totalMinutes}分`
          );
      }
    } else {
      setTimeout(calculateAndUpdateDuration, 300);
    }
  };

  calculateAndUpdateDuration();
};

const updateAuthorName = (authorName: string) => {
  const targetElement = document.querySelector(
    "body[class^=slug-0] .FS-Course-DetailColumns > div > div > div > div > div > div > div:nth-child(5)"
  );

  if (targetElement && targetElement.children.length > 1) {
    const reviewElement = targetElement.children[1].children[0] as HTMLElement;
    const ratingElement = targetElement.children[1].children[1] as HTMLElement;

    if (reviewElement) {
      reviewElement.textContent = "講師名";
    }

    if (ratingElement) {
      ratingElement.textContent = authorName ? authorName : "未登録";
    }

    targetElement.classList.add("block");
  }
};

export default CourseDataFetcher;
