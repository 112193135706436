import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import FlowComponent from "./dynamicContents/flow";
import FaqComponent from "./dynamicContents/faq";
import LicenseComponent from "./dynamicContents/license";

let attempts = 0;
const maxAttempts = 20; // 10秒間（500ms * 20回）

export default function dynamicContentsInsert() {
  const intervalId = setInterval(function () {
    var elements = document.querySelectorAll(".js-FS-Dynamic-Contents-Insert");
    elements.forEach(function (element) {
      if (element) {
        var dataAttr = element.getAttribute("data-fs-dynamic-contents-insert");
        if (dataAttr) {
          var values = dataAttr.split(",");
          const componentsToRender = [];
          if (values.includes("flow")) {
            componentsToRender.push(<FlowComponent key="flow" />);
          }
          if (values.includes("faq")) {
            componentsToRender.push(<FaqComponent key="faq" />);
          }
          if (values.includes("license")) {
            componentsToRender.push(<LicenseComponent key="license" />);
          }
          const root = createRoot(element);
          root.render(<React.Fragment>{componentsToRender}</React.Fragment>);
        }
        clearInterval(intervalId);
      }
    });
    attempts++;
    if (attempts >= maxAttempts) {
      clearInterval(intervalId);
    }
  }, 500);
}
