import React, { useState, useEffect } from "react";
import { HomePC } from "./anima/Home/HomePC";
import { HomeMobile } from "./anima/Home/HomeMobile";
import ShadowDomWrapper from "./components/ShadowDomWrapper";
import { AboutConcept } from "./anima/Home/AboutConcept";

export const Home = ShadowDomWrapper(() => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 510);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 510);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile ? (
    <>
      <HomeMobile />
      <AboutConcept isMobile={true} />
    </>
  ) : (
    <>
      <HomePC />
      <AboutConcept isMobile={false} />
    </>
  );
});
